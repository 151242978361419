<template>
  <div class="page_tpl">

    <div class="wavesCont">
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" style="isolation:isolate" preserveAspectRatio="none" viewBox="5978 129.24 1440 259.76">
      <defs>
        <clipPath id="a">
          <path d="M5978 129.24h1440V389H5978z"></path>
        </clipPath>
      </defs>
      <g fill="#FFF" clip-path="url(#a)">
        <path style="animation-delay: .2s" d="M5978 153.77c166.44 0 358.45 11.66 755.24 138.08 381.36 121.5 562.3 105.94 684.76 75.81 0-15.48-.02-54.72-.1-155.11-137.43 39.67-283.82 106.09-717.65 27.58-407.86-73.8-571.8-93.89-721.75-93.89l-.5 7.53z" fill-opacity=".1"></path>
        <path style="animation-delay: .4s" d="M5978 153.77c166.44 0 358.45 11.66 755.24 138.08 381.36 121.5 562.3 105.94 684.76 75.81l-.04-53.2-1.54.37c-122.36 30.1-294.49 72.46-680.18-34.53C6334.99 169 6181.93 151.26 5978 151.26v2.51z" fill-opacity=".1"></path>
        <path d="M7418 367.66V389H5978V153.77c166.44 0 358.45 11.66 755.24 138.08C6965.46 365.84 7123.37 389 7239 389c74.27 0 131.1-9.56 178.99-21.34q0-70.8 0 0z"></path>
      </g>
    </svg> 
      </div>
    <div class="cgu">
  
      
      <div class="content" @click="edit_etat = !edit_etat" v-if="content" v-html="compiledMarkdown"></div>
      
      <div class="edit" v-if="$store.state.user.logged && $store.state.user.user.superbo && edit_etat">
        <button class="submit" @click="submit">Enregistrer</button>
        <el-input type="textarea" autosize v-model="content"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked'
export default {
  name: "cgu",
  data() {
    return {
      content: null,
      edit_etat: false
    }
  },
  computed: {
    compiledMarkdown: function() {
      return marked(this.content, { sanitize: true });
    }
  },
  created() {
    this.$loader = true
    this.$http.get('/content/cgu').then((c) => {
      this.content = c.data
      this.$loader = false
    })
  },
  methods: {
    submit() {
      this.$http.put('/content', {
        content: this.content,
        key: 'cgu'
      }).then(() => alert('save ok'))
    }
  }
}
</script>

<style>
  .cgu { display: flex; padding: 0 36px; justify-content: center; position: relative; z-index: 2; }
  .cgu .content { width: 100%; font-size: 16px; line-height: 20px; margin-top: 36px; font-weight: 400; }
  .cgu .content p { font-size: 16px; line-height: 1.7; }
  .cgu .edit { width: 50%; }
  .cgu .edit .submit { margin-bottom: 16px; margin-top: 16px; }
  
  .cgu h1 { color: #fff; margin-bottom: 35vw; font-size: 38px; line-height: 38px; }
  .cgu h2 { font-size: 30px; line-height: 40px; margin-bottom: 36px; }
  .cgu h3 { font-size: 26px; line-height: 30px; margin-top: 46px; margin-bottom: 26px; }
  .cgu ol { padding-left: 16px; margin-top: 36px; }
  .cgu li { font-weight: bold; }



  .page_tpl .wavesCont { position: absolute; top: 69px; height: 64vw; right: 0; left: 0; background: var(--orange); transform: rotateY(180deg) }
  .page_tpl .wavesCont .waves { position: absolute; bottom: -1px; left: 0px; right: 0; }

  .page_tpl .waves-container.no-animation * {
    animation: none!important;
  }
  .page_tpl .waves path {
    animation: waveIn .7s both;
  }
  .page_tpl .waves path:last-child {
    animation: none;
  }
  @keyframes waveIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }


  @media only screen and (min-width: 600px) {
    .cgu .content { width: 70%; }
    
    /*.wavesCont { height: 40vh; }*/
    /*.cgu h1 { font-size: 50px; line-height: 52px; width: 70%; margin-top: 0.9vw; margin-bottom: 16vw; }*/
    .page_tpl .wavesCont { height: 280px; width: 160%; }
    .cgu h1 { margin-top: 1.8vw; margin-bottom: 140px; font-size: 50px; line-height: 52px; width: 70%; }
  }
  @media only screen and (min-width: 1400px) {
    .cgu h1 { margin-bottom: 180px; }
  }
</style>